<template>
  <div >
    <div style="line-height: 1.4; margin-bottom: 10px" :style="{ fontSize: fontSize, fontWeight: 'bold', color: color }">
      {{ main }}
      <span class="text-primary">{{ sub }}</span>
    </div>
    <div class="separator bg-primary"/>
  </div>
</template>

<script>
export default {
  name: 'LeftTitle',
  props: {
    fontSize: {
      type: String,
      default: '32px'
    },
    main: {
      type: String,
      default: ''
    },
    sub: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#333'
    }
  }
}
</script>

<style scoped>
  .separator{
    display: inline-block;
    height: 3px;
    width: 50px;
    margin-bottom: 10px;
    position: relative;
  }
</style>
